@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Montserrat:wght@400;600;700&family=Space+Mono&display=swap');
/* 
Orange #FF1616
Blue #3E6B89
Background #F5F5F5
Rhone Grey rgb(210, 207, 207)
*/

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #F5F5F5;
}
a {
  text-decoration: none;
}

.walks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 500%;
  font-weight: bolder;
  color: black;
  margin: 0;
}
.h2 {
  color: #e20000;
  padding: 10px 10px 0px 10px;
  margin: 0;
}
.icons {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.home-first {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: #3E6B89;
}
.home-second {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 75%;
}
.home-third {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
}
.home-third ul {
  display: flex;
  list-style-type: none;
}
.home-words {
  width: 60%;
  padding: 20px;
  margin: 20px 20px 100px 20px;
  border: 5px solid black;
  font-size: large;
  font-family: 'Space Mono', monospace;
  list-style-type: none;
}

.about-first {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: #3E6B89;
}
.about-second {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 75%;
}
.about-third {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
}
.about-third p {
  width: 60%;
  padding: 20px;
  margin: 20px;
  border: px solid black;
}
.self {
  width: 50%;
  border: 7px solid black;
  border-radius: 50%;
  outline: 10px solid black;
  margin-bottom: 30px;
}
.connect-first {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  color: black;
}
.connect-second {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 75%;
}
.connect-third {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
}
.connect-third p {
  text-align: left;
  width: 100%;
  font-size: xx-large;
}
.connect-third a {
  color: black;
}
.connect-links {
  padding: 30px;
  margin: 20px;
  box-shadow: 0px 1px 25px lightslategray;
  border: 5px solid black;
  background-image: linear-gradient(to bottom right, #FF1616, #3E6B89);
}

.techstack-first {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 1px 25px lightslategray;
  border: 5px solid black;
  background-image: linear-gradient(to bottom right, #FF1616, #3E6B89);
}
.techstack-first ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
  list-style-type: none;
  color: black;
  margin: 10px;
}
.techstack-first ul > :first-child {
  font-size: large;
  font-weight: bolder;
}

.project-first {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
  background-color: #F5F5F5;
  font-family: 'Montserrat', sans-serif;
}
.project-second {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 75%;
}
.project-third {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.projects {
  width: 100%;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
}
.projects li {
  margin-top: 50px;
}
.projects img {
  height: auto;
  width: 100%;
  border: 10px solid black;
}
.projects a {
  color: #FF1616;
  font-size: x-large;
  font-family: 'Space Mono', monospace;
}

.oneliners {
  list-style-type: none;
  width: 60%;
  padding: 20px;
  margin: 20px 20px 100px 20px;
  font-size: large;
  font-family: 'Space Mono', monospace;
  text-align: left;
}

.footer-first {
  position: fixed;
  bottom: 0;
  height: 7.5%;
  width: 100%;
  color: #F5F5F5;
  background-color: black;
  font-family: 'Hammersmith One', sans-serif;
  box-shadow: 1px 5px 15px #28282B;
}
.footer-first ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  text-align: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.footer-first li {
  width: 100%;
}
.footer-first p {
  margin: 0%;
}
.footer-first img {
  height: 20%;
  width: auto;
}
.footer-first a {
  color: whitesmoke;
}

.navbar-first {
  position: fixed;
  top: 0;
  background-color: black;
  height: 6%;
  width: 100%;
  font-family: 'Hammersmith One', sans-serif;
  font-size: medium;
  box-shadow: 1px 5px 15px #28282B;
}
.navbar-first a {
  color: #F5F5F5;
}
.navbar-first a:hover {
  color: #FF1616;
}
.navbar-first ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  margin: 10px;
  justify-content: space-around;
  list-style-type: none;
}


/* MOBILE */
.navbarmobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 10%;
  font-family: 'Hammersmith One', sans-serif;
  font-size: medium;
  color: whitesmoke;
  background-color: black;
  box-shadow: 1px 5px 15px #28282B;
}
.footermobile {
  position: fixed;
  bottom: 0;
  height: 6%;
  width: 100%;
  color: #F5F5F5;
  background-color: black;
  font-family: 'Hammersmith One', sans-serif;
  box-shadow: 1px 5px 15px #28282B;
}
.navbarmenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  width: 85%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.navbarmenu li {
  margin-top: -10px;
}
.menuimage {
  width: 40px;
  height: 40px;
}
.menu-options {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  position: absolute;
  padding: 0;
  top: 35px;
  left: -100%;
  opacity: 0;
  transition: all 0.5s ease;
  flex-direction: column;
  list-style-type: none;
}
.menu-options li {
  display: flex;
  justify-content: center;
  font-size: x-large;
  height: 100%;
  width: 100%;
  background-color: black;
}
.menu-options.active {
  background-color: black;
  left: 0px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}
.navbarmobile a {
  color: whitesmoke;
}

.home-words-mobile {
  width: 100%;
  padding: 10px;
  margin: 10px 10px 50px 10px;
  border: 3px solid black;
  font-size: medium;
  font-family: 'Space Mono', monospace;
  list-style-type: none;
}
.icons-mobile {
  display: flex;
  list-style-type: none;
  padding: 0;
}
.icons-mobile img {
  height: 30px;
  width: auto;
}
.h1-mobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 300%;
  font-weight: bolder;
  color: black;
}
.h2-mobile {
  color: #FF1616;
  padding: 10px 10px 0px 10px;
  font-size: 100%;
  margin: 0;
}
.selfmobile {
  width: 90%;
  border-radius: 50%;
  border: 7px solid black;
  margin-bottom: 30px;
}
.projects-mobile a {
  color: #FF1616;
  font-size: small;
}
.home-font a {
  color: whitesmoke;
}
